import styled from 'styled-components';



const MainHeader = styled.h1`
    font-size: 4rem;
    margin: 0;
`;

const SubTitle = styled.h2`
    font-size: 2rem;
`; 

const HeaderContainer = styled.div`
    overflow-x: hidden;
`;


export function Header() {
  return <HeaderContainer>
    <MainHeader>abrovinc</MainHeader>
    <SubTitle>Smart solution to a tricky problem!</SubTitle>
  </HeaderContainer>;
}