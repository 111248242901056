import styled from 'styled-components';
import Title from './HeaderTitle';


interface EmployeeProp {
    name: String,
    experienceLevel: String,
    mainFocus: String,
    techStack: String,
    linkedinUrl: String,
    image: string
}

const EmployeeTitle = styled.h1`
    font-size: 2.35rem;
`;

const EmployeeWrapper = styled.div`
    padding-bottom: 5px;
`;

const EmployeeImg = styled.img`
    width: 18.75rem;
    border-radius: 20%;
    max-width: 80%;
`; 

const Link = styled.a`
    color: #FFFFFF;
    &:hover, &:active {
        color: #B3B6B7;
    }
`;

const Description = styled.p`
    margin-left: 1rem;
    margin-right: 1rem;
`;


export function Employee({
  name,
  experienceLevel,
  mainFocus,
  techStack,
  linkedinUrl,
  image
}: EmployeeProp) {
  return <EmployeeWrapper>
    <EmployeeTitle className="member-name">{name}</EmployeeTitle>
    <EmployeeImg src={image} alt={`employee ${name}`}/>
    <Description>
            Experience level: {experienceLevel} <br/>
            Main focus: {mainFocus} <br/>
            Tech stack: {techStack} <br/>
            Linkedin: <Link href={'https://www.linkedin.com/in/' + linkedinUrl} target={'_blank'} rel="noreferrer">{name}</Link>
    </Description>
  </EmployeeWrapper>;
}




interface EmployeesProp {
    employees: EmployeeProp[]
}

export function Employees({ employees }: EmployeesProp) {
  return <div className="employees">
    <Title>Members of Abrovinc</Title>
    {employees.map((employee, key) => <Employee key={key} {...employee} />)}
  </div>;
}
