/* eslint-disable */
// @ts-ignore
const FloatingBanner = () => {

  const openExternalLink = () => {
    window.open('https://www.linkedin.com/in/steve-widinghoff/', '_blank');
  };

  return (
      <div>
        <div
            style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: '100%',
              background: '#00222b',
              padding: '20px',
              textAlign: 'center',
            }}
        >
          <p
              style={{
                margin: 0,
                fontSize: '1.5rem',
                color: '#fff',
                cursor: 'pointer',
              }}
              onClick={openExternalLink}
          >
            Contact us at Linkedin
          </p>
        </div>
      </div>
  );
};

export default FloatingBanner;