import styled from 'styled-components';
import Title from './HeaderTitle';

const Description = styled.p`
    margin-left: 1rem;
    margin-right: 1rem;
`;

export function WhatWeDo() {
  return <div className="whatwedo-container">
    <Title>What we do, now and in the future!</Title>
    <Description>IT consultants with focus on scalability, cloud, microservices and more. <br />
            Long term goal to build our own SAAS solutions.</Description>
  </div>;
}