
import { Helmet } from 'react-helmet';

export const MetaTags = () => {
  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>Abrovinc</title>
      <meta name="description" content="IT Consultants with focus on scalability, cloud, microservices and more. Long term goal to build our own SaaS solutions." />            
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Abrovinc" />
      <meta property="og:description" content="IT Consultants with focus on scalability, cloud, microservices and more. Long term goal to build our own SaaS solutions." />
      <meta property="og:url" content="https://abrovinc.com" />
            
      {/* 
            <meta property="og:image" content="https://media.gettyimages.com/photos/bogota-at-sunset-picture-id107069344?s=612x612" />            
            */ }
    </Helmet>        
  );
};