import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body {
        background-color: #001318;
        width: 100%;
        margin: 0px;
        padding: 0px;
        overflow-x: hidden;

        margin: 0;
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    code {
      font-family: 'Roboto', sans-serif;
    }
`;

export default GlobalStyle;
